import React, { useRef, useEffect, useState } from "react";
import "./Poste.css";
import { PostObj, SortedElement } from "../../../Models/Post";
import { useLocation } from "react-router-dom";
import { downloadPoster } from "../../../Services/Posts";

const Post: React.FunctionComponent<{}> = (props) => {

    const [loading, setLoading] = useState(true);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const downloadCanvasRef = useRef<HTMLCanvasElement>(null);
    const locationState = useLocation();
    const [postValue, setPostValue] = useState<PostObj>(locationState.state && locationState.state)
    const [isUpgrade, setIsUpgrade] = useState<boolean>(false);
    const [showMsgUpgrade, setShowMsgUpgrade] = useState<any>("");
    const drawRectangleWithFittingCircle = (
        ctx: CanvasRenderingContext2D,
        rectX: number,
        rectY: number,
        rectWidth: number,
        rectHeight: number,
        rectColor: string,
        circleColor: string
    ) => {
        // Draw the rectangle
        ctx.fillStyle = rectColor;
        ctx.fillRect(rectX, rectY, rectWidth, rectHeight);

        // Calculate circle dimensions
        const radius = Math.min(rectWidth, rectHeight) / 2;
        const circleX = rectX + rectWidth / 2;
        const circleY = rectY + rectHeight / 2;

        // Draw the circle
        ctx.fillStyle = circleColor;
        ctx.beginPath();
        ctx.arc(circleX, circleY, radius, 0, Math.PI * 2);
        ctx.fill();
    };

    const drawImageInCircleV2 = (
        ctx: CanvasRenderingContext2D,
        img: HTMLImageElement | null,
        x: number,
        y: number,
        width: number,
        height: number,
        borderColor?: string, // Default border color
        borderWidth?: number, // Default border width
        backgroundColor?: string, // Default background color
        rotation?: number // Rotation angle in degrees
    ) => {
        // Convert rotation from degrees to radians
        const angleRadians = rotation ? rotation * (Math.PI / 180) : 0;

        // Calculate the center and radii of the ellipse
        const centerX = x + width / 2;
        const centerY = y + height / 2;
        const radiusX = width / 2;
        const radiusY = height / 2;

        // Save the current context state
        ctx.save();

        // Apply transformations for drawing the ellipse
        ctx.translate(centerX, centerY);
        ctx.rotate(angleRadians);
        ctx.translate(-centerX, -centerY);

        // Draw the ellipse background
        ctx.fillStyle = backgroundColor || "transparent";
        ctx.beginPath();
        ctx.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI);
        ctx.fill();

        // Draw the ellipse border
        if (borderWidth && borderColor) {
            ctx.strokeStyle = borderColor;
            ctx.lineWidth = borderWidth || 0.000000000001;
            ctx.stroke();
        }

        // Clip the context to the ellipse
        ctx.beginPath();
        ctx.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI);
        ctx.clip();

        if (img) {
            // Calculate the bounding box of the circle
            const diameter = Math.min(width, height);
            const offsetX = (width - diameter) / 2;
            const offsetY = (height - diameter) / 2;
            // Draw the image inside the ellipse, ensuring it fills the circle
            ctx.drawImage(
                img,
                x + offsetX, // Adjust x position to center the image
                y + offsetY, // Adjust y position to center the image
                diameter, // Adjust width to the diameter of the circle
                diameter // Adjust height to the diameter of the circle
            );
        } else {
            // Draw a solid color background inside the ellipse
            ctx.fillStyle = backgroundColor || "transparent";
            ctx.beginPath();
            ctx.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI);
            ctx.fill();
        }

        // Restore context to the original state
        ctx.restore();
    };

    const drawImageInRoundedRectangle = (
        ctx: CanvasRenderingContext2D,
        img: HTMLImageElement, // Allow null for the image
        x: number,
        y: number,
        width: number,
        height: number,
        radii?: {
            topLeft?: number;
            topRight?: number;
            bottomLeft?: number;
            bottomRight?: number;
        },
        borderColor?: string, // Default border color
        borderWidth?: number, // Rotation angle in degrees
        rotation?: number
    ) => {
        const {
            topLeft = 0,
            topRight = 0,
            bottomLeft = 0,
            bottomRight = 0,
        } = radii || {};

        // Calculate adjusted radii considering border width
        const adjustedTopLeft = Math.max(topLeft - borderWidth! / 2, 0);
        const adjustedTopRight = Math.max(topRight - borderWidth! / 2, 0);
        const adjustedBottomLeft = Math.max(bottomLeft - borderWidth! / 2, 0);
        const adjustedBottomRight = Math.max(bottomRight - borderWidth! / 2, 0);

        ctx.save(); // Save the current context state

        // Apply rotation if specified
        if (rotation) {
            const radians = rotation * (Math.PI / 180); // Convert degrees to radians
            const cx = x + width / 2; // Center of rotation (x)
            const cy = y + height / 2; // Center of rotation (y)
            ctx.translate(cx, cy); // Move the origin to the center of the rectangle
            ctx.rotate(radians); // Rotate the canvas
            ctx.translate(-cx, -cy); // Move the origin back to the top-left corner
        }

        // Draw the rounded rectangle path for the border
        ctx.beginPath();
        ctx.moveTo(x + adjustedTopLeft, y);
        ctx.arcTo(x + width, y, x + width, y + height, adjustedTopRight);
        ctx.arcTo(x + width, y + height, x, y + height, adjustedBottomRight);
        ctx.arcTo(x, y + height, x, y, adjustedBottomLeft);
        ctx.arcTo(x, y, x + width, y, adjustedTopLeft);
        ctx.closePath();

        // Draw the border
        ctx.lineWidth = borderWidth || 0.0000001;
        ctx.strokeStyle = borderColor || "";
        ctx.stroke();

        // Begin path for rounded rectangle
        ctx.beginPath();

        // Top-left corner
        ctx.moveTo(x + topLeft, y);
        ctx.arcTo(x + width, y, x + width, y + height, topRight);

        // Top-right corner
        ctx.arcTo(x + width, y + height, x, y + height, bottomRight);

        // Bottom-left corner
        ctx.arcTo(x, y + height, x, y, bottomLeft);

        // Bottom-right corner
        ctx.arcTo(x, y, x + width, y, topLeft);

        ctx.closePath();
        ctx.clip();

        ctx.drawImage(
            img,
            x, // Adjust x position to fit the rounded rectangle
            y, // Adjust y position to fit the rounded rectangle
            width, // Adjust width to fit the rounded rectangle
            height // Adjust height to fit the rounded rectangle
        );
        // Draw the image inside the rounded rectangl

        ctx.restore(); // Restore the context to its original state
    };

    const drawbackroundInRoundedRectangle = (
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        width: number,
        height: number,
        radii?: {
            topLeft?: number;
            topRight?: number;
            bottomLeft?: number;
            bottomRight?: number;
        },
        borderColor?: string, // Default border color
        borderWidth?: number, // Rotation angle in degrees
        backgroundColor?: string, // Background color when no image is provided// Rotation angle in degrees

        rotation?: number
    ) => {
        const {
            topLeft = 0,
            topRight = 0,
            bottomLeft = 0,
            bottomRight = 0,
        } = radii || {};

        // Calculate adjusted radii considering border width
        const adjustedTopLeft = Math.max(topLeft - borderWidth! / 2, 0);
        const adjustedTopRight = Math.max(topRight - borderWidth! / 2, 0);
        const adjustedBottomLeft = Math.max(bottomLeft - borderWidth! / 2, 0);
        const adjustedBottomRight = Math.max(bottomRight - borderWidth! / 2, 0);

        ctx.save(); // Save the current context state

        // Apply rotation if specified
        if (rotation) {
            const radians = rotation * (Math.PI / 180); // Convert degrees to radians
            const cx = x + width / 2; // Center of rotation (x)
            const cy = y + height / 2; // Center of rotation (y)
            ctx.translate(cx, cy); // Move the origin to the center of the rectangle
            ctx.rotate(radians); // Rotate the canvas
            ctx.translate(-cx, -cy); // Move the origin back to the top-left corner
        }

        // Draw the rounded rectangle path for the border
        ctx.beginPath();
        ctx.moveTo(x + adjustedTopLeft, y);
        ctx.arcTo(x + width, y, x + width, y + height, adjustedTopRight);
        ctx.arcTo(x + width, y + height, x, y + height, adjustedBottomRight);
        ctx.arcTo(x, y + height, x, y, adjustedBottomLeft);
        ctx.arcTo(x, y, x + width, y, adjustedTopLeft);
        ctx.closePath();

        // Draw the border
        ctx.lineWidth = borderWidth || 0.0000001;
        ctx.strokeStyle = borderColor || "";
        ctx.stroke();

        // Begin path for rounded rectangle
        ctx.beginPath();

        // Top-left corner
        ctx.moveTo(x + topLeft, y);
        ctx.arcTo(x + width, y, x + width, y + height, topRight);

        // Top-right corner
        ctx.arcTo(x + width, y + height, x, y + height, bottomRight);

        // Bottom-left corner
        ctx.arcTo(x, y + height, x, y, bottomLeft);

        // Bottom-right corner
        ctx.arcTo(x, y, x + width, y, topLeft);

        ctx.closePath();
        ctx.clip();

        ctx.fillStyle = backgroundColor || "";
        ctx.fill();

        ctx.restore(); // Restore the context to its original state
    };

    // Function to draw a rounded rectangle

    const drawPlaceholders = async (
        ctx: CanvasRenderingContext2D,
        placeholders: any
    ) => {
        placeholders?.forEach((placeholder: any) => {
            const {
                x = 0,
                y = 0,
                type,
                width = 0,
                height = 0,
                points,
                circleColor,
                backgroundColor = "rgba(0, 0, 0, 0)", // Default to transparent if no color provided
                borderColor = "", // Default border color
                borderWidth = 0, // Default border width
                img,
                radii,
                rotation = 0, // Default rotation angle
            } = placeholder;

            switch (type) {
                case "rectangle":
                    drawbackroundInRoundedRectangle(
                        ctx,
                        x,
                        y,
                        width,
                        height,
                        radii,
                        borderColor,
                        borderWidth,
                        backgroundColor,
                        rotation
                    );
                    break;

                case "circle":
                    drawRectangleWithFittingCircle(
                        ctx,
                        x,
                        y,
                        width,
                        height,
                        backgroundColor,
                        circleColor
                    );
                    break;

                case "circleV2":
                    drawImageInCircleV2(
                        ctx,
                        img,
                        x,
                        y,
                        width,
                        height,
                        borderColor,
                        borderWidth,
                        backgroundColor,
                        rotation
                    );
                    break;

                case "polygon":
                    if (points) {
                        ctx.beginPath();
                        ctx.moveTo(points[0]?.x, points[0]?.y);
                        points.forEach((point: any, i: any) => {
                            if (i > 0) ctx.lineTo(point.x, point.y);
                        });
                        ctx.closePath();

                        // Set fill color for the polygon
                        ctx.fillStyle = backgroundColor;
                        ctx.fill(); // Fill the polygon with color

                        // Set border properties
                        ctx.strokeStyle = borderColor || ""; // Default border color if none provided
                        ctx.lineWidth = borderWidth || 0.000001; // Default border width if none provided
                        ctx.stroke();
                    }
                    break;
            }
        });
    };

    const loadImages = (imageUrls: string[]): Promise<HTMLImageElement[]> => {
        return new Promise((resolve, reject) => {
            const images: HTMLImageElement[] = [];
            let loadedCount = 0;

            imageUrls.forEach((url, index) => {
                const img = new Image();
                img.crossOrigin = "anonymous";

                img.src = url;

                img.onload = () => {
                    loadedCount++;
                    images[index] = img;
                    if (loadedCount === imageUrls.length) {
                        resolve(images);
                    }
                };

                img.onerror = () => {
                    reject(new Error(`Failed to load image at ${url}`));
                };
            });
        });
    };
    const drawImages = async (
        ctx: CanvasRenderingContext2D,
        placeholdersImage: any[],
        images: HTMLImageElement | HTMLImageElement[]
    ) => {
        const isSingleImage = images instanceof HTMLImageElement;

        if (isSingleImage) {
            const img = images;

            placeholdersImage.forEach((placeholder, index) => {
                if (!img) {
                    console.warn(`Image not found for placeholder ${index}`);
                    return;
                }

                switch (placeholder.type) {
                    case "rectangle":
                        drawImageInRoundedRectangle(
                            ctx,
                            img,
                            placeholder.x ?? 0,
                            placeholder.y ?? 0,
                            placeholder.width ?? 0,
                            placeholder.height ?? 0,
                            placeholder.radii,
                            placeholder.borderColor ?? "",
                            placeholder.borderWidth ?? 0,
                            placeholder.rotation ?? 0
                        );
                        break;

                    case "circle":
                        ctx.save();
                        ctx.beginPath();
                        ctx.arc(
                            placeholder.x,
                            placeholder.y,
                            placeholder.radius ?? 0,
                            0,
                            2 * Math.PI
                        );
                        ctx.clip();
                        ctx.drawImage(
                            img,
                            placeholder.x - (placeholder.radius ?? 0),
                            placeholder.y - (placeholder.radius ?? 0),
                            (placeholder.radius ?? 0) * 2,
                            (placeholder.radius ?? 0) * 2
                        );
                        ctx.restore();
                        break;

                    case "circleV2":
                        drawImageInCircleV2(
                            ctx,
                            img,
                            placeholder.x ?? 0,
                            placeholder.y ?? 0,
                            placeholder.width ?? 0,
                            placeholder.height ?? 0,
                            placeholder.borderColor ?? "",
                            placeholder.borderWidth ?? 0,
                            placeholder.backgroundColor ?? "",
                            placeholder.rotation ?? 0
                        );
                        break;

                    case "polygon":
                        if (placeholder.points) {
                            const minX = Math.min(...placeholder.points.map((p: any) => p.x));
                            const minY = Math.min(...placeholder.points.map((p: any) => p.y));
                            const maxX = Math.max(...placeholder.points.map((p: any) => p.x));
                            const maxY = Math.max(...placeholder.points.map((p: any) => p.y));

                            const width = maxX - minX;
                            const height = maxY - minY;

                            ctx.save();
                            ctx.beginPath();
                            ctx.moveTo(placeholder.points[0].x, placeholder.points[0].y);
                            placeholder.points.forEach((point: { x: number; y: number; }) => ctx.lineTo(point.x, point.y));
                            ctx.closePath();

                            // Set border properties
                            ctx.strokeStyle = placeholder.borderColor || ""; // Border color
                            ctx.lineWidth = placeholder.borderWidth || 0.0000001;           // Border width
                            ctx.stroke();                 // Draw border

                            ctx.clip();                   // Clip the following drawing to the polygon area

                            ctx.drawImage(img, minX, minY, width, height); // Draw the image
                            ctx.restore();
                        }
                        break;


                    default:
                        console.warn(`Unknown placeholder type: ${placeholder.type}`);
                }
            });
        } else if (
            Array.isArray(images) &&
            images.every((img) => img instanceof HTMLImageElement)
        ) {
            placeholdersImage.forEach((placeholder, index) => {
                const img = images[index];

                if (!img) {
                    console.warn(`Image not found for placeholder ${index}`);
                    return;
                }

                switch (placeholder.type) {
                    case "rectangle":
                        drawImageInRoundedRectangle(
                            ctx,
                            img,
                            placeholder.x ?? 0,
                            placeholder.y ?? 0,
                            placeholder.width ?? 0,
                            placeholder.height ?? 0,
                            placeholder.radii,
                            placeholder.borderColor ?? "",
                            placeholder.borderWidth ?? 0,
                            placeholder.rotation ?? 0
                        );
                        break;

                    case "circle":
                        ctx.save();
                        ctx.beginPath();
                        ctx.arc(
                            placeholder.x,
                            placeholder.y,
                            placeholder.radius ?? 0,
                            0,
                            2 * Math.PI
                        );
                        ctx.clip();
                        ctx.drawImage(
                            img,
                            placeholder.x - (placeholder.radius ?? 0),
                            placeholder.y - (placeholder.radius ?? 0),
                            (placeholder.radius ?? 0) * 2,
                            (placeholder.radius ?? 0) * 2
                        );
                        ctx.restore();
                        break;

                    case "circleV2":
                        drawImageInCircleV2(
                            ctx,
                            img,
                            placeholder.x ?? 0,
                            placeholder.y ?? 0,
                            placeholder.width ?? 0,
                            placeholder.height ?? 0,
                            placeholder.borderColor ?? "",
                            placeholder.borderWidth ?? 0,
                            placeholder.backgroundColor ?? "",
                            placeholder.rotation ?? 0
                        );
                        break;

                    case "polygon":
                        if (placeholder.points) {
                            const minX = Math.min(
                                ...placeholder.points.map((p: { x: number }) => p.x)
                            );
                            const minY = Math.min(
                                ...placeholder.points.map((p: { y: number }) => p.y)
                            );
                            const maxX = Math.max(
                                ...placeholder.points.map((p: { x: number }) => p.x)
                            );
                            const maxY = Math.max(
                                ...placeholder.points.map((p: { y: number }) => p.y)
                            );

                            const width = maxX - minX;
                            const height = maxY - minY;

                            ctx.save();
                            ctx.beginPath();
                            ctx.moveTo(placeholder.points[0].x, placeholder.points[0].y);
                            placeholder.points.forEach((point: { x: number; y: number }) =>
                                ctx.lineTo(point.x, point.y)
                            );
                            ctx.closePath();
                            ctx.clip();

                            ctx.drawImage(img, minX, minY, width, height);
                            ctx.restore();
                        }
                        break;

                    default:
                        console.warn(`Unknown placeholder type: ${placeholder.type}`);
                }
            });
        }
    };

    const drawLines = async (
        context: CanvasRenderingContext2D,
        startX: number,
        startY: number,
        endX: number,
        endY: number,
        color: string,
        lineWidth: number = 1
    ) => {

        context.beginPath();
        context.strokeStyle = color;
        context.lineWidth = lineWidth;
        context.moveTo(startX, startY);
        context.lineTo(endX, endY);
        context.stroke();

    };

    const wrapTextLeft = async (
        context: CanvasRenderingContext2D,
        text: string,
        x: number,
        y: number,
        maxWidth: number,
        lineHeight: number,
        font: string,
        color: string
    ) => {
        context.fillStyle = color;
        context.font = ` ${font}`;

        let words = text.split(" ");
        let line = "";
        let yOffset = y;

        for (let n = 0; n < words.length; n++) {
            let testLine = line + words[n] + " ";
            let metrics = context.measureText(testLine);
            let testWidth = metrics.width;

            if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, yOffset);
                line = words[n] + " ";
                yOffset += lineHeight;
            } else {
                line = testLine;
            }
        }

        context.fillText(line, x, yOffset);
    };

    const wrapTextCenter = async (
        context: CanvasRenderingContext2D,
        text: string,
        x: number,
        y: number,
        maxWidth: number,
        lineHeight: number,
        font: string,
        color: string
    ) => {
        context.fillStyle = color;
        context.font = `${font}`;
        let words = text.split(" ");
        let line = "";
        let yOffset = y;

        for (let n = 0; n < words.length; n++) {
            let testLine = line + words[n] + " ";
            let metrics = context.measureText(testLine);
            let testWidth = metrics.width;

            if (testWidth > maxWidth && n > 0) {
                context.fillText(
                    line.trim(),
                    x + (maxWidth - context.measureText(line.trim()).width) / 2,
                    yOffset
                );
                line = words[n] + " ";
                yOffset += lineHeight;
            } else {
                line = testLine;
            }
        }

        context.fillText(
            line.trim(),
            x + (maxWidth - context.measureText(line.trim()).width) / 2,
            yOffset
        );
    };

    const wrapTextRight = async (
        context: CanvasRenderingContext2D,
        text: string,
        x: number,
        y: number,
        maxWidth: number,
        lineHeight: number,
        font: string,
        color: string
    ) => {
        context.fillStyle = color;
        context.font = ` ${font}`;

        let words = text.split(" ");
        let line = "";
        let yOffset = y;

        for (let n = 0; n < words.length; n++) {
            let testLine = line + words[n] + " ";
            let metrics = context.measureText(testLine);
            let testWidth = metrics.width;

            if (testWidth > maxWidth && n > 0) {
                // Draw the text aligned to the right
                context.fillText(
                    line.trim(),
                    x - context.measureText(line.trim()).width,
                    yOffset
                );
                line = words[n] + " ";
                yOffset += lineHeight;
            } else {
                line = testLine;
            }
        }

        // Draw the last line aligned to the right
        context.fillText(
            line.trim(),
            x - context.measureText(line.trim()).width,
            yOffset
        );
    };

    const resizeTextToFit = (
        ctx: CanvasRenderingContext2D,
        text: string,
        maxWidth: number,
        maxHeight: number,
        initialFontSize: number = 0,
        initialLineHeight: number = 0,
        fontFamily: string = ""
    ): { fontSize: number; lineHeight: number } => {
        const doesTextFit = (fontSize: number, lineHeight: number): boolean => {
            ctx.font = `${fontSize}px ${fontFamily}`;
            const words = text.split(" ");
            let line = "";
            let currentY = 0;

            for (let i = 0; i < words.length; i++) {
                const testLine = line + words[i] + " ";
                const metrics = ctx.measureText(testLine);
                const testWidth = metrics.width;

                if (testWidth > maxWidth && i > 0) {
                    if (currentY + lineHeight > maxHeight) {
                        return false; // Does not fit
                    }
                    line = words[i] + " ";
                    currentY += lineHeight;
                } else {
                    line = testLine;
                }
            }

            return currentY + lineHeight <= maxHeight; // Check if the last line fits
        };

        // Initialize font size and line height
        let fontSize = initialFontSize;
        let lineHeight = initialLineHeight;

        // Decrease font size and line height until text fits both width and height
        while (fontSize > 0 && lineHeight > 0) {
            if (doesTextFit(fontSize, lineHeight)) {
                return { fontSize, lineHeight }; // Return the font size and line height that fit
            }
            fontSize -= 1; // Decrease font size
            lineHeight = Math.max(1, lineHeight - 1); // Decrease line height, ensure it does not go below 1
        }

        // Return the smallest font size and line height that can fit within the constraints
        return { fontSize: 0, lineHeight: 0 };
    };

    const wrapTextCenterRotation = async (
        ctx: CanvasRenderingContext2D,
        text: string,
        x: number,
        y: number,
        maxWidth: number,
        maxHeight: number,
        initialFontSize: number,
        rotation: number = 0,
        color: string = "",
        fontFamily: string = "",
        lineHeight: number = 0,
        topSpacing: number = 8, // Added parameter for top spacing
        alignment: "center" | "left" | "right" = "center", // Alignment parameter
        fontWeight: string | number = "normal",
        borderColor: string // Added parameter to control bold text
    ) => {
        // Use the existing resizeTextToFit function to determine the appropriate font size and line height
        const { fontSize, lineHeight: adjustedLineHeight } = resizeTextToFit(
            ctx,
            text,
            maxWidth,
            maxHeight,
            initialFontSize,
            lineHeight,
            fontFamily
        );
        let fontWeightStr: string;
        if (fontWeight === "bold") {
            fontWeightStr = "bold";
        } else if (
            typeof fontWeight === "number" &&
            [300, 400, 500, 700, 900].includes(fontWeight)
        ) {
            fontWeightStr = fontWeight.toString();
        } else {
            fontWeightStr = "normal";
        }

        // Set the font to the chosen size and weight
        ctx.font = `${fontWeightStr} ${fontSize}px ${fontFamily}`;
        ctx.fillStyle = color;

        // Convert the angle to radians
        const radians = rotation * (Math.PI / 180);

        // Save the context state
        ctx.save();

        // Move the canvas context to the center of the rectangle
        ctx.translate(x + maxWidth / 2, y + maxHeight / 2);

        // Rotate the canvas context
        ctx.rotate(radians);

        // Move the context back to the top-left of the rectangle
        ctx.translate(-maxWidth / 2, -maxHeight / 2);
        if (borderColor) {
            // // Draw the rectangle for visualization
            ctx.strokeStyle = "blue"; // Rectangle border color
            ctx.lineWidth = 2; // Rectangle border width
            ctx.strokeRect(0, 0, maxWidth, maxHeight);
        }

        // Set text properties
        ctx.textBaseline = "top"; // Ensures text starts drawing from the top

        // Set text alignment based on parameter
        switch (alignment) {
            case "left":
                ctx.textAlign = "left";
                break;
            case "right":
                ctx.textAlign = "right";
                break;
            case "center":
            default:
                ctx.textAlign = "center";
                break;
        }

        const words = text.split(" ");
        let line = "";
        let currentY = topSpacing; // Start drawing text with top spacing

        for (let i = 0; i < words.length; i++) {
            const testLine = line + words[i] + " ";
            const metrics = ctx.measureText(testLine);
            const testWidth = metrics.width;

            if (testWidth > maxWidth && i > 0) {
                if (currentY + adjustedLineHeight > maxHeight) {
                    break; // Stop if adding another line would exceed maxHeight
                }
                // Draw the current line based on alignment
                ctx.fillText(
                    line.trim(),
                    alignment === "left"
                        ? 0
                        : alignment === "right"
                            ? maxWidth
                            : maxWidth / 2,
                    currentY
                );
                line = words[i] + " ";
                currentY += adjustedLineHeight;
            } else {
                line = testLine;
            }
        }

        // Draw the last line
        ctx.fillText(
            line.trim(),
            alignment === "left"
                ? 0
                : alignment === "right"
                    ? maxWidth
                    : maxWidth / 2,
            currentY
        );

        // Restore the context state
        ctx.restore();
    };

    // Assume resizeTextToFit is defined elsewhere

    useEffect(() => {
        setLoading(false)
        if (canvasRef.current) {
            const combinedElements: any = [
                ...(postValue?.jsondata?.data || []).map((item) => ({
                    ...item,
                })),
            ];
            // Sort combined elements by zIndex
            const sortedElements: SortedElement[] = combinedElements.sort(
                (a: any, b: any) => (a.zIndex || 0) - (b.zIndex || 0)
            );

            if (sortedElements.some((element) => element.zIndex)) {
                const imageUrls = sortedElements
                    .map((item: any) => item.src) // Extract the src
                    .filter((src: any) => src !== undefined);
                loadImages(imageUrls)
                    .then((images) => {
                        const canvas = canvasRef.current;
                        if (!canvas) return; // Ensure canvas is not null
                        const ctx = canvas.getContext("2d");
                        if (ctx) {
                            ctx.clearRect(0, 0, canvas.width, canvas.height);
                            ctx.fillStyle = "#FFFFFF"; // Background color
                            ctx.fillRect(0, 0, canvas.width, canvas.height);
                            ctx.fillStyle = "#FFFFFF";
                            let i: number = 0;

                            sortedElements.forEach(async (item) => {
                                switch (item.types) {
                                    case "image":
                                        const img = images[i];
                                        i++;

                                        await drawImages(ctx, [item], img);
                                        break;

                                    case "placeholder":
                                        await drawPlaceholders(ctx, [item]);
                                        break;

                                    case "text":
                                        ctx.fillStyle = item.color || "#FFFFFF";

                                        if (item.type === "wrapTextLeft") {
                                            await wrapTextLeft(
                                                ctx,
                                                item.text || "",
                                                item.x ?? 355,
                                                item.y ?? 560,
                                                item.maxWidth ?? 280,
                                                item.lineHeight ?? 80,
                                                item.font || "",
                                                item.color || "#FFFFFF"
                                            );
                                        } else if (item.type === "wrapTextRight") {
                                            await wrapTextRight(
                                                ctx,
                                                item.text || "",
                                                item.x ?? 52,
                                                item.y ?? 88,
                                                item.maxWidth ?? 406,
                                                item.lineHeight ?? 40,
                                                item.font || "",
                                                item.color || "#FFFFFF"
                                            );
                                        } else if (item.type === "wrapTextCenter") {
                                            await wrapTextCenter(
                                                ctx,
                                                item.text || "",
                                                item.x ?? 0,
                                                item.y ?? 200,
                                                item.maxWidth ?? 406,
                                                item.lineHeight ?? 40,
                                                item.font || "",
                                                item.color || "#FFFFFF"
                                            );
                                        } else if (item.alignment) {
                                            await wrapTextCenterRotation(
                                                ctx,
                                                item.text || "write something",
                                                item.x ?? 0,
                                                item.y ?? 0,
                                                item.maxWidth ?? 0,
                                                item.maxHeight ?? 0,
                                                item.font ?? 0, // Default font size
                                                item.rotation ?? 0,
                                                item.color ?? "black",
                                                item.fontFamily ?? "Arial",
                                                item.lineHeight ?? 0,
                                                item.topSpacing ?? 8,
                                                item.alignment ?? "center",
                                                item.fontWeight ?? "",
                                                item.borderColor ?? ""
                                            );
                                        }
                                        break;
                                    case "line":
                                        await drawLines(
                                            ctx,
                                            item.startX ?? 0,
                                            item.startY ?? 0,
                                            item.endX ?? 0,
                                            item.endY ?? 0,
                                            item.color ?? "",
                                            item.lineWidth ?? 1
                                        );
                                        break;

                                    default:
                                        console.warn(`Unknown type: `);
                                }
                            });
                            drawWatermark(ctx);
                        }
                    })
                    .catch((error: any) => {
                        console.error("Error loading images:", error);
                    });
            } else {
                const imageUrls: any =
                    postValue?.jsondata?.placeholdersImage &&
                    postValue?.jsondata?.placeholdersImage.map(
                        (placeholder: any) => placeholder.src
                    );
                loadImages(imageUrls)
                    .then((images) => {
                        const canvas = canvasRef.current;
                        if (!canvas) return; // Ensure canvas is not null
                        const ctx = canvas.getContext("2d");
                        if (ctx) {
                            ctx.clearRect(0, 0, canvas.width, canvas.height);
                            ctx.fillStyle = "#FFFFFF"; // Background color
                            ctx.fillRect(0, 0, canvas.width, canvas.height);
                            ctx.fillStyle = "#FFFFFF";
                            drawPlaceholders(ctx, postValue?.jsondata?.placeholders);
                            drawImages(
                                ctx,
                                postValue?.jsondata?.placeholdersImage || [],
                                images
                            );
                            drawPlaceholders(ctx, postValue?.jsondata?.placeholdersText);
                            postValue?.jsondata?.textData?.forEach((item) => {
                                ctx.fillStyle = item.color || "#FFFFFF";
                                if (item.type === "wrapTextLeft") {
                                    wrapTextLeft(
                                        ctx,
                                        item.text || "",
                                        item.x ?? 355,
                                        item.y ?? 560,
                                        item.maxWidth ?? 280,
                                        item.lineHeight ?? 80,
                                        item.font || "",
                                        item.color || "#FFFFFF"
                                    );
                                } else if (item.type === "wrapTextRight") {
                                    wrapTextRight(
                                        ctx,
                                        item.text || "",
                                        item.x ?? 52,
                                        item.y ?? 88,
                                        item.maxWidth ?? 406,
                                        item.lineHeight ?? 40,
                                        item.font || "",
                                        item.color || "#FFFFFF"
                                    );
                                } else if (item.type === "wrapTextCenter") {
                                    wrapTextCenter(
                                        ctx,
                                        item.text || "",
                                        item.x ?? 0,
                                        item.y ?? 200,
                                        item.maxWidth ?? 406,
                                        item.lineHeight ?? 40,
                                        item.font || "",
                                        item.color || "#FFFFFF"
                                    );
                                } else if (item.alignment) {
                                    wrapTextCenterRotation(
                                        ctx,
                                        item.text || "write some think",
                                        item.x ?? 0,
                                        item.y ?? 0,
                                        item.maxWidth ?? 0,
                                        item.maxHeight ?? 0,
                                        item.font ?? 0, // Default font size
                                        item.rotation ?? 0,
                                        item.color ?? "black",
                                        item.fontFamily ?? "Arial",
                                        item.lineHeight ?? 0,
                                        item.topSpacing ?? 8,
                                        item.alignment ?? "center",
                                        item.fontWeight ?? "",
                                        item.borderColor ?? ""
                                    );
                                }
                            });
                            drawWatermark(ctx);

                        }
                    })
                    .catch((error: any) => {
                        console.error("Error loading images:", error);
                    });
            }
        }
    }, [
        postValue?.jsondata?.placeholdersImage,
        drawImages,
        postValue?.jsondata?.linesData,
        postValue?.jsondata?.placeholders,
        postValue?.jsondata?.textData,
        wrapTextCenter,
        wrapTextLeft,
        wrapTextRight,
    ]);

    const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const result = e.target?.result as string;
                if (result) {
                    setPostValue(prevOptions => {
                        if (!prevOptions.jsondata?.placeholdersImage || prevOptions.jsondata.placeholdersImage.length <= index) {
                            return prevOptions;
                        }
                        const updatedPlaceholdersImage = prevOptions.jsondata.placeholdersImage.map((image, i) =>
                            i === index ? { ...image, src: result } : image
                        );
                        return {
                            ...prevOptions,
                            jsondata: {
                                ...prevOptions.jsondata,
                                placeholdersImage: updatedPlaceholdersImage
                            }
                        };
                    });
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleChangeText = (event: any, indexText: any) => {
        const newText = event.target.value;
        // const initialLength = getInitialLength(postValue.jsondata?.textData && postValue.jsondata?.textData[indexText].text);
        // if (newText.length <= initialLength)
        setPostValue(prevOptions => {
            if (!prevOptions.jsondata?.textData || prevOptions.jsondata.textData.length <= 1) {
                return prevOptions;
            }

            const updatedTextData = prevOptions.jsondata.textData.map((item, index) =>
                index === indexText ? { ...item, text: newText } : item
            );

            return {
                ...prevOptions,
                jsondata: {
                    ...prevOptions.jsondata,
                    textData: updatedTextData
                }
            };
        });
    };

    const downloadImage = () => {
        if (downloadCanvasRef.current) {
            const combinedElements: any = [
                ...(postValue?.jsondata?.data || []).map((item) => ({
                    ...item,
                })),
            ];
            // Sort combined elements by zIndex
            const sortedElements: SortedElement[] = combinedElements.sort(
                (a: any, b: any) => (a.zIndex || 0) - (b.zIndex || 0)
            );

            if (sortedElements.some((element) => element.zIndex)) {
                const imageUrls = sortedElements
                    .map((item: any) => item.src) // Extract the src
                    .filter((src: any) => src !== undefined);
                loadImages(imageUrls)
                    .then((images) => {
                        const canvas = canvasRef.current;
                        if (!canvas) return; // Ensure canvas is not null
                        const ctx = canvas.getContext("2d");
                        if (ctx) {
                            ctx.clearRect(0, 0, canvas.width, canvas.height);
                            ctx.fillStyle = "#FFFFFF"; // Background color
                            ctx.fillRect(0, 0, canvas.width, canvas.height);
                            ctx.fillStyle = "#FFFFFF";
                            let i: number = 0;

                            sortedElements.forEach(async (item) => {
                                switch (item.types) {
                                    case "image":
                                        const img = images[i];
                                        i++;

                                        await drawImages(ctx, [item], img);
                                        break;

                                    case "placeholder":
                                        await drawPlaceholders(ctx, [item]);
                                        break;

                                    case "text":
                                        ctx.fillStyle = item.color || "#FFFFFF";

                                        if (item.type === "wrapTextLeft") {
                                            await wrapTextLeft(
                                                ctx,
                                                item.text || "",
                                                item.x ?? 355,
                                                item.y ?? 560,
                                                item.maxWidth ?? 280,
                                                item.lineHeight ?? 80,
                                                item.font || "",
                                                item.color || "#FFFFFF"
                                            );
                                        } else if (item.type === "wrapTextRight") {
                                            await wrapTextRight(
                                                ctx,
                                                item.text || "",
                                                item.x ?? 52,
                                                item.y ?? 88,
                                                item.maxWidth ?? 406,
                                                item.lineHeight ?? 40,
                                                item.font || "",
                                                item.color || "#FFFFFF"
                                            );
                                        } else if (item.type === "wrapTextCenter") {
                                            await wrapTextCenter(
                                                ctx,
                                                item.text || "",
                                                item.x ?? 0,
                                                item.y ?? 200,
                                                item.maxWidth ?? 406,
                                                item.lineHeight ?? 40,
                                                item.font || "",
                                                item.color || "#FFFFFF"
                                            );
                                        } else if (item.alignment) {
                                            await wrapTextCenterRotation(
                                                ctx,
                                                item.text || "write something",
                                                item.x ?? 0,
                                                item.y ?? 0,
                                                item.maxWidth ?? 0,
                                                item.maxHeight ?? 0,
                                                item.font ?? 0, // Default font size
                                                item.rotation ?? 0,
                                                item.color ?? "black",
                                                item.fontFamily ?? "Arial",
                                                item.lineHeight ?? 0,
                                                item.topSpacing ?? 8,
                                                item.alignment ?? "center",
                                                item.fontWeight ?? "",
                                                item.borderColor ?? ""
                                            );
                                        }
                                        break;
                                    case "line":
                                        await drawLines(
                                            ctx,
                                            item.startX ?? 0,
                                            item.startY ?? 0,
                                            item.endX ?? 0,
                                            item.endY ?? 0,
                                            item.color ?? "",
                                            item.lineWidth ?? 1
                                        );
                                        break;

                                    default:
                                        console.warn(`Unknown type: `);
                                }
                            });
                            drawWatermark(ctx);
                        }
                    })
                    .catch((error: any) => {
                        console.error("Error loading images:", error);
                    });
            } else {
                const imageUrls: any =
                    postValue?.jsondata?.placeholdersImage &&
                    postValue?.jsondata?.placeholdersImage.map(
                        (placeholder: any) => placeholder.src
                    );
                loadImages(imageUrls)
                    .then((images) => {
                        const canvas = canvasRef.current;
                        if (!canvas) return; // Ensure canvas is not null
                        const ctx = canvas.getContext("2d");
                        if (ctx) {
                            ctx.clearRect(0, 0, canvas.width, canvas.height);
                            ctx.fillStyle = "#FFFFFF"; // Background color
                            ctx.fillRect(0, 0, canvas.width, canvas.height);
                            ctx.fillStyle = "#FFFFFF";
                            drawPlaceholders(ctx, postValue?.jsondata?.placeholders);
                            drawImages(
                                ctx,
                                postValue?.jsondata?.placeholdersImage || [],
                                images
                            );
                            drawPlaceholders(ctx, postValue?.jsondata?.placeholdersText);
                            postValue?.jsondata?.textData?.forEach((item) => {
                                ctx.fillStyle = item.color || "#FFFFFF";
                                if (item.type === "wrapTextLeft") {
                                    wrapTextLeft(
                                        ctx,
                                        item.text || "",
                                        item.x ?? 355,
                                        item.y ?? 560,
                                        item.maxWidth ?? 280,
                                        item.lineHeight ?? 80,
                                        item.font || "",
                                        item.color || "#FFFFFF"
                                    );
                                } else if (item.type === "wrapTextRight") {
                                    wrapTextRight(
                                        ctx,
                                        item.text || "",
                                        item.x ?? 52,
                                        item.y ?? 88,
                                        item.maxWidth ?? 406,
                                        item.lineHeight ?? 40,
                                        item.font || "",
                                        item.color || "#FFFFFF"
                                    );
                                } else if (item.type === "wrapTextCenter") {
                                    wrapTextCenter(
                                        ctx,
                                        item.text || "",
                                        item.x ?? 0,
                                        item.y ?? 200,
                                        item.maxWidth ?? 406,
                                        item.lineHeight ?? 40,
                                        item.font || "",
                                        item.color || "#FFFFFF"
                                    );
                                } else if (item.alignment) {
                                    wrapTextCenterRotation(
                                        ctx,
                                        item.text || "write some think",
                                        item.x ?? 0,
                                        item.y ?? 0,
                                        item.maxWidth ?? 0,
                                        item.maxHeight ?? 0,
                                        item.font ?? 0, // Default font size
                                        item.rotation ?? 0,
                                        item.color ?? "black",
                                        item.fontFamily ?? "Arial",
                                        item.lineHeight ?? 0,
                                        item.topSpacing ?? 8,
                                        item.alignment ?? "center",
                                        item.fontWeight ?? "",
                                        item.borderColor ?? ""
                                    );
                                }
                            });
                            drawWatermark(ctx);

                        }
                    })
                    .catch((error: any) => {
                        console.error("Error loading images:", error);
                    });
            }
        }
    };

    const drawWatermark = (ctx: any) => {
        ctx.font = "bold 50px Roboto";

        ctx.fillStyle = "rgba(0, 0, 0, 0)";
        ctx.lineWidth = 1;
        ctx.strokeStyle = "rgba(113,107,227,1) ";

        const positions = [
            { x: 30, y: 70 },
            { x: postValue?.jsondata?.width && postValue.jsondata.width - 250, y: 70 },
            { x: 30, y: postValue?.jsondata?.height && postValue?.jsondata?.height - 50 },
            { x: postValue?.jsondata?.width && postValue?.jsondata?.width - 250, y: postValue?.jsondata?.height && postValue?.jsondata?.height - 50 }
        ];

        positions.forEach(pos => {
            ctx.fillText("Coralytics", pos.x, pos.y);

            ctx.strokeText("Coralytics", pos.x, pos.y);
        });

        const centerX = (postValue?.jsondata?.width ?? 0) / 2;
        const centerY = (postValue?.jsondata?.height ?? 0) / 2;

        const textWidth = ctx.measureText("Coralytics").width;
        const centerTextX = centerX - (textWidth / 2);
        const centerTextY = centerY + 25;

        ctx.fillText("Coralytics", centerTextX, centerTextY);
        ctx.strokeText("Coralytics", centerTextX, centerTextY);
    };

    // useEffect(() => {
    //     setLoading(false)
    //     console.log(locationState.state)
    //     if (canvasRef.current) {
    //         const imageUrls: any = postValue?.jsondata?.placeholdersImage && postValue?.jsondata?.placeholdersImage.map((placeholder: any) => placeholder.src);
    //         loadImages(imageUrls).then((images) => {
    //             const canvas = canvasRef.current;
    //             if (!canvas) return; // Ensure canvas is not null
    //             const ctx = canvas.getContext("2d");
    //             if (ctx) {
    //                 ctx.clearRect(0, 0, canvas.width, canvas.height);
    //                 ctx.fillStyle = "#FFFFFF"; // Background color
    //                 ctx.fillRect(0, 0, canvas.width, canvas.height)
    //                 ctx.fillStyle = "#FFFFFF";
    //                 drawPlaceholders(ctx, postValue?.jsondata?.placeholders);
    //                 drawPlaceholdersImage(ctx, postValue?.jsondata?.placeholdersImage);
    //                 drawImages(ctx, postValue?.jsondata?.placeholdersImage || [], images);
    //                 drawPlaceholders(ctx, postValue?.jsondata?.placeholdersText);
    //                 postValue?.jsondata?.textData?.forEach((item) => {
    //                     ctx.fillStyle = item.color || "#FFFFFF";
    //                     const text = item.text || "";
    //                     if (item.type === "wrapTextLeft") {
    //                         wrapTextLeft(
    //                             ctx,
    //                             item.text || "",
    //                             item.x ?? 355,
    //                             item.y ?? 560,
    //                             item.maxWidth ?? 280,
    //                             item.lineHeight ?? 80,
    //                             item.font || "",
    //                             item.color || "#FFFFFF"
    //                         );
    //                     } else if (item.type === "wrapTextRight") {
    //                         wrapTextRight(
    //                             ctx,
    //                             item.text || "",
    //                             item.x ?? 52,
    //                             item.y ?? 88,
    //                             item.maxWidth ?? 406,
    //                             item.lineHeight ?? 40,
    //                             item.font || "",
    //                             item.color || "#FFFFFF"
    //                         );
    //                     } else if (item.type === "wrapTextCenter") {
    //                         wrapTextCenter(
    //                             ctx,
    //                             item.text || "",
    //                             item.x ?? 52,
    //                             item.y ?? 200,
    //                             item.maxWidth ?? 406,
    //                             item.lineHeight ?? 40,
    //                             item.font || "",
    //                             item.color || "#FFFFFF"
    //                         );
    //                     }
    //                 });

    //                 // Draw lines
    //                 const linesData: any = postValue?.jsondata?.linesData ?? [];
    //                 drawLines(ctx, linesData, 3);
    //                 drawWatermark(ctx);
    //             }

    //         }).catch((error: any) => {
    //             console.error('Error loading images:', error);
    //         });
    //     }
    // }, [
    //     postValue?.jsondata?.placeholdersImage,
    //     drawImages,
    //     postValue?.jsondata?.linesData,
    //     postValue?.jsondata?.placeholders,
    //     postValue?.jsondata?.textData,
    //     wrapTextCenter,
    //     wrapTextLeft,
    //     wrapTextRight,
    // ]);

    return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> Loading ...</div>) : (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-md-12 desc-property">
                    <div className="card ">
                        <div className="card-body">
                            <div className="row  ">
                                <div className="col-md-7 mb-3">
                                    <div className="title-card">
                                        Generate {postValue.type === "poster" ? "Poster" : "Reel"}
                                    </div>
                                </div>
                                <div className="col-md-5 mb-3 padd-24 search-generate" >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card " style={{ marginBottom: "100px" }}>
                <div className="card-body">
                    {isUpgrade && <div className="alert alert-danger alert-dismissible mt-4" role="alert">
                        {showMsgUpgrade}
                    </div>}
                    <div className="row">
                        <div className={`mb-4 ${postValue.type === "poster" ? "col-md-4" : "col-md-5"}`} style={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingLeft: "30px" }}>
                            {postValue?.jsondata?.textData?.map((text: any, index: any) => {
                                if (text.text.toUpperCase() === "JUST LISTED" || text.text.toUpperCase() === ("MEET THE TEAM") || text.text.toUpperCase() === "JUST SOLD") {
                                    return null;
                                }
                                return (
                                    <input
                                        type="text"
                                        key={index}
                                        className="form-control input mt-3"
                                        value={text.text || ''}
                                        onChange={(e: any) => {
                                            handleChangeText(e, index);
                                        }}
                                    />
                                );
                            })}
                            {postValue?.jsondata?.placeholdersImage?.map((image: any, index: number) => {
                                if (image.class && image.class === "icon") {
                                    return null;
                                }
                                return (
                                    <div className='profile-upload mt-3' key={index}>
                                        <div>
                                            <img src={image.src} alt={`Placeholder ${index}`} />
                                            Image.PNG
                                        </div>
                                        <label htmlFor={`input-file-${index}`} className="form-label">
                                            <input
                                                type="file"
                                                id={`input-file-${index}`}
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                                onChange={(e) => handleChangeImage(e, index)}
                                            />
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M5.53999 19.5196C4.92999 19.5196 4.35999 19.3096 3.94999 18.9196C3.42999 18.4296 3.17999 17.6896 3.26999 16.8896L3.63999 13.6496C3.70999 13.0396 4.07999 12.2296 4.50999 11.7896L12.72 3.09956C14.77 0.929561 16.91 0.869561 19.08 2.91956C21.25 4.96956 21.31 7.10956 19.26 9.27956L11.05 17.9696C10.63 18.4196 9.84999 18.8396 9.23999 18.9396L6.01999 19.4896C5.84999 19.4996 5.69999 19.5196 5.53999 19.5196ZM15.93 2.90956C15.16 2.90956 14.49 3.38956 13.81 4.10956L5.59999 12.8096C5.39999 13.0196 5.16999 13.5196 5.12999 13.8096L4.75999 17.0496C4.71999 17.3796 4.79999 17.6496 4.97999 17.8196C5.15999 17.9896 5.42999 18.0496 5.75999 17.9996L8.97999 17.4496C9.26999 17.3996 9.74999 17.1396 9.94999 16.9296L18.16 8.23956C19.4 6.91956 19.85 5.69956 18.04 3.99956C17.24 3.22956 16.55 2.90956 15.93 2.90956Z"
                                                    fill="#282828"
                                                />
                                                <path
                                                    d="M17.3404 10.9498C17.3204 10.9498 17.2904 10.9498 17.2704 10.9498C14.1504 10.6398 11.6404 8.26985 11.1604 5.16985C11.1004 4.75985 11.3804 4.37985 11.7904 4.30985C12.2004 4.24985 12.5804 4.52985 12.6504 4.93985C13.0304 7.35985 14.9904 9.21985 17.4304 9.45985C17.8404 9.49985 18.1404 9.86985 18.1004 10.2798C18.0504 10.6598 17.7204 10.9498 17.3404 10.9498Z"
                                                    fill="#282828"
                                                />
                                                <path
                                                    d="M21 22.75H3C2.59 22.75 2.25 22.41 2.25 22C2.25 21.59 2.59 21.25 3 21.25H21C21.41 21.25 21.75 21.59 21.75 22C21.75 22.41 21.41 22.75 21 22.75Z"
                                                    fill="#282828"
                                                />
                                            </svg>
                                        </label>
                                    </div>
                                );
                            })}
                            {postValue?.jsondata?.data?.map((text: any, index: any) => {
                                if (text.types == "text") {
                                    if (text.text.toUpperCase() === "JUST LISTED" || text.text.toUpperCase() === ("MEET THE TEAM") || text.text.toUpperCase() === "JUST SOLD") {
                                        return null;
                                    }
                                    return (
                                        <input
                                            type="text"
                                            key={index}
                                            className="form-control input mt-3"
                                            value={text.text || ''}
                                            onChange={(e: any) => {
                                                handleChangeText(e, index);
                                            }}
                                        />
                                    );
                                }
                            })}
                            {postValue?.jsondata?.data?.map((image: any, index: number) => {
                                if (image.types == "image") {
                                    if (image.class && image.class === "icon") {
                                        return null;
                                    }
                                    return (
                                        <div className='profile-upload mt-3' key={index}>
                                            <div>
                                                <img src={image.src} alt={`Placeholder ${index}`} />
                                                Image.PNG
                                            </div>
                                            <label htmlFor={`input-file-${index}`} className="form-label">
                                                <input
                                                    type="file"
                                                    id={`input-file-${index}`}
                                                    style={{ display: 'none' }}
                                                    accept="image/*"
                                                    onChange={(e) => handleChangeImage(e, index)}
                                                />
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M5.53999 19.5196C4.92999 19.5196 4.35999 19.3096 3.94999 18.9196C3.42999 18.4296 3.17999 17.6896 3.26999 16.8896L3.63999 13.6496C3.70999 13.0396 4.07999 12.2296 4.50999 11.7896L12.72 3.09956C14.77 0.929561 16.91 0.869561 19.08 2.91956C21.25 4.96956 21.31 7.10956 19.26 9.27956L11.05 17.9696C10.63 18.4196 9.84999 18.8396 9.23999 18.9396L6.01999 19.4896C5.84999 19.4996 5.69999 19.5196 5.53999 19.5196ZM15.93 2.90956C15.16 2.90956 14.49 3.38956 13.81 4.10956L5.59999 12.8096C5.39999 13.0196 5.16999 13.5196 5.12999 13.8096L4.75999 17.0496C4.71999 17.3796 4.79999 17.6496 4.97999 17.8196C5.15999 17.9896 5.42999 18.0496 5.75999 17.9996L8.97999 17.4496C9.26999 17.3996 9.74999 17.1396 9.94999 16.9296L18.16 8.23956C19.4 6.91956 19.85 5.69956 18.04 3.99956C17.24 3.22956 16.55 2.90956 15.93 2.90956Z"
                                                        fill="#282828"
                                                    />
                                                    <path
                                                        d="M17.3404 10.9498C17.3204 10.9498 17.2904 10.9498 17.2704 10.9498C14.1504 10.6398 11.6404 8.26985 11.1604 5.16985C11.1004 4.75985 11.3804 4.37985 11.7904 4.30985C12.2004 4.24985 12.5804 4.52985 12.6504 4.93985C13.0304 7.35985 14.9904 9.21985 17.4304 9.45985C17.8404 9.49985 18.1404 9.86985 18.1004 10.2798C18.0504 10.6598 17.7204 10.9498 17.3404 10.9498Z"
                                                        fill="#282828"
                                                    />
                                                    <path
                                                        d="M21 22.75H3C2.59 22.75 2.25 22.41 2.25 22C2.25 21.59 2.59 21.25 3 21.25H21C21.41 21.25 21.75 21.59 21.75 22C21.75 22.41 21.41 22.75 21 22.75Z"
                                                        fill="#282828"
                                                    />
                                                </svg>
                                            </label>
                                        </div>
                                    );
                                }
                            })}
                            <div className="generate-edit mb-4  mt-4" style={{ display: "flex", justifyContent: "end" }}>

                                <button type="button" className={`btn mb-2`} onClick={downloadImage}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5003 18.9584H7.50033C2.97533 18.9584 1.04199 17.0251 1.04199 12.5001V7.50008C1.04199 2.97508 2.97533 1.04175 7.50033 1.04175H12.5003C17.0253 1.04175 18.9587 2.97508 18.9587 7.50008V12.5001C18.9587 17.0251 17.0253 18.9584 12.5003 18.9584ZM7.50033 2.29175C3.65866 2.29175 2.29199 3.65841 2.29199 7.50008V12.5001C2.29199 16.3417 3.65866 17.7084 7.50033 17.7084H12.5003C16.342 17.7084 17.7087 16.3417 17.7087 12.5001V7.50008C17.7087 3.65841 16.342 2.29175 12.5003 2.29175H7.50033Z" fill="#716BE3" />
                                        <path d="M9.99987 12.7167C9.84154 12.7167 9.6832 12.6583 9.5582 12.5333L7.0582 10.0333C6.81654 9.79166 6.81654 9.39167 7.0582 9.15C7.29987 8.90833 7.69987 8.90833 7.94153 9.15L9.99987 11.2083L12.0582 9.15C12.2999 8.90833 12.6999 8.90833 12.9415 9.15C13.1832 9.39167 13.1832 9.79166 12.9415 10.0333L10.4415 12.5333C10.3165 12.6583 10.1582 12.7167 9.99987 12.7167Z" fill="#716BE3" />
                                        <path d="M10 12.7167C9.65833 12.7167 9.375 12.4334 9.375 12.0917V5.42505C9.375 5.08338 9.65833 4.80005 10 4.80005C10.3417 4.80005 10.625 5.08338 10.625 5.42505V12.0917C10.625 12.4417 10.3417 12.7167 10 12.7167Z" fill="#716BE3" />
                                        <path d="M9.99971 15.1916C8.24138 15.1916 6.47471 14.9083 4.79971 14.35C4.47471 14.2416 4.29971 13.8833 4.40804 13.5583C4.51638 13.2333 4.86637 13.05 5.19971 13.1666C8.29971 14.2 11.708 14.2 14.808 13.1666C15.133 13.0583 15.4914 13.2333 15.5997 13.5583C15.708 13.8833 15.533 14.2416 15.208 14.35C13.5247 14.9166 11.758 15.1916 9.99971 15.1916Z" fill="#716BE3" />
                                    </svg>
                                    Download
                                </button>

                            </div>
                        </div>
                        <div className={`${postValue.type === "poster" ? "col-md-8" : "col-md-7"}`} style={{ padding: "20px 30px 20px 20px" }}>
                            <canvas
                                id="canvas"
                                ref={canvasRef}
                                width={postValue?.jsondata?.width}
                                height={postValue?.jsondata?.height}
                                style={{ border: "1px solid #d1d3d5" }}
                                onContextMenu={(e) => e.preventDefault()}
                                onDragStart={(e) => e.preventDefault()}
                            />
                            <canvas
                                id="downloadCanvas"
                                ref={downloadCanvasRef}
                                width={postValue?.jsondata?.width}
                                height={postValue?.jsondata?.height}
                                style={{ display: "none" }}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Post;
